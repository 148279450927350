import styled from "styled-components";
import { Link } from "react-scroll";
import { FaMapMarkerAlt, FaPhoneAlt, FaEnvelope } from "react-icons/fa";

export const FooterContainer = styled.footer`
  background: ${(props) => props.theme.colors.footerBg};
`;

export const FooterWrapper = styled.div`
  padding: 48px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
`;

export const FooterLinksContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 800px;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.xs}) {
    padding-top: 32px;
    flex-wrap: wrap;
  }
`;

export const FooterLinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: ${(props) => (props.stretch ? 1 : "unset")};

  @media screen and (max-width: ${(props) => props.theme.breakpoints.xs}) {
    flex-direction: column;
    width: 100%;
    flex: unset;
  }
`;

export const FooterLinkItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 16px;
  text-align: left;
  width: 160px;
  box-sizing: border-box;
  color: ${(props) => props.theme.colors.primary};

  @media screen and (max-width: ${(props) => props.theme.breakpoints.xs}) {
    margin: 0;
    padding: 10px;
    width: 100%;
    align-items: center;
  }
`;

export const FooterLinkTitle = styled.h1`
  font-size: 14px;
  margin-bottom: 16px;
`;

export const FooterKontaktTitle = styled.h4`
  color: ${(props) => props.theme.colors.primary};
  margin-bottom: 10px;
`;

export const AddressIcon = styled(FaMapMarkerAlt)`
  width: 20px;
`;

export const FooterKontaktWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  color: ${(props) => props.theme.colors.primary};
  margin-bottom: 0.5rem;
  font-size: 14px;
`;

export const PhoneIcon = styled(FaPhoneAlt)`
  width: 20px;
`;

export const MailIcon = styled(FaEnvelope)`
  width: 20px;
`;

export const FooterLink = styled(Link)`
  color: ${(props) => props.theme.colors.primary};
  text-decoration: none;
  margin-bottom: 0.5rem;
  font-size: 14px;
  transform: scale(1);
  transition: 0.3s ease-in-out;
  padding-left: 0;
  cursor: pointer;

  &:hover {
    color: ${(props) => props.color};
    transform: scale(1.1);
    transition: 0.3s ease-in-out;
    padding-left: 10px;
  }
`;

export const SocialMedia = styled.section`
  max-width: 1000px;
  width: 100%;
`;

export const SocialMediaWrapper = styled.div`
  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  max-width: 1100px;
  margin: 40px auto 0 auto;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.xs}) {
    flex-direction: column;
    grid-template-columns: 1fr;
  }
`;

export const FooterLogoWrapper = styled(Link)`
  color: ${(props) => props.theme.colors.primary};
  justify-content: flex-end;
  cursor: pointer;
  text-decoration: none;
  font-size: 1.5rem;
  display: flex;
  margin-bottom: 16px;
  font-weight: bold;
  width: 100%;
  padding-top: 16px;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.xs}) {
    justify-content: center;
    padding-top: 10px;
  }
`;

export const FooterLogo = styled.img`
  max-height: 50px;
`;

export const WebsiteRights = styled.small`
  color: ${(props) => props.theme.colors.primary};
  margin-bottom: 16px;
  justify-self: center;
`;

export const SocialIcons = styled.div`
  display: flex;
  gap: 16px;
  justify-content: flex-end;
  align-items: center;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.xs}) {
    justify-content: center;
  }
`;

export const SocialIconLink = styled.a`
  color: ${(props) => props.theme.colors.primary};
  font-size: 24px;
`;

export const MemberWrapper = styled.div`
  display: flex;
  padding: 0 16px;
  flex-direction: column;
`;
