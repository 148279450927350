import React from "react";
import { FaLinkedin } from "react-icons/fa";
import { animateScroll as scroll } from "react-scroll";
import {
  AddressIcon,
  FooterContainer,
  FooterLink,
  FooterLinkItems,
  FooterLinksContainer,
  FooterLinksWrapper,
  FooterLinkTitle,
  FooterKontaktTitle,
  FooterWrapper,
  FooterLogoWrapper,
  FooterLogo,
  SocialIconLink,
  SocialIcons,
  PhoneIcon,
  FooterKontaktWrapper,
  MailIcon,
  MemberWrapper,
} from "./FooterElements";
import { NavItems } from "../Navbar/NavItems";

import Logo from "../../images/RMD Logo_Lang.svg";
import LogoWhite from "../../images/RMD Logo_Weiss.svg";
import { useTranslation } from "react-i18next";
import svs_logo from "../../images/svs_logo.svg";
import svs_logo_dark from "../../images/svs_logo_dark.svg";

const Footer = ({ darkMode }) => {

  const {t} = useTranslation();

  const toggleHome = () => {
    scroll.scrollToTop();
  };

  return (
    <FooterContainer>
      <FooterWrapper>
        <FooterLinksContainer>
          <FooterLinksWrapper stretch={true}>
            <FooterLinkItems>
              <FooterKontaktTitle>{t("company.name")}</FooterKontaktTitle>
              <FooterKontaktWrapper>
                <AddressIcon />
                <span>
                  Niederfeld 48
                  <br />
                  9320 Stachen
                </span>
              </FooterKontaktWrapper>
              <FooterKontaktWrapper>
                <PhoneIcon />
                +41 76 698 98 78
              </FooterKontaktWrapper>
              <FooterKontaktWrapper>
                <MailIcon />
                info@rmdtrading.ch
              </FooterKontaktWrapper>
              <FooterKontaktWrapper>
                CHE-323.403.646
              </FooterKontaktWrapper>
            </FooterLinkItems>
          </FooterLinksWrapper>
          <FooterLinksWrapper stretch={true}>
            <FooterLinkItems>
              <FooterLinkTitle>{t("menu.sections")}</FooterLinkTitle>
              {NavItems.map((element, i) => {
                return (
                  <FooterLink
                    key={element.titel}
                    to={element.anchor}
                    smooth={true}
                    duration={500}
                    exact="true"
                    offset={-80}
                    color={element.color}
                  >
                    {t(element.titel)}
                  </FooterLink>
                );
              })}
            </FooterLinkItems>
          </FooterLinksWrapper>
          <FooterLinksWrapper>
            <MemberWrapper>
            <FooterLogoWrapper to="https://www.spielwarenverband.ch/verband/mitglieder/rmd-trading-ag/" target="_blank">
              <FooterLogo src={darkMode ? svs_logo : svs_logo_dark} />
            </FooterLogoWrapper>
            </MemberWrapper>
          </FooterLinksWrapper>
          <FooterLinksWrapper>
            <FooterLogoWrapper to="/">
              <FooterLogo onClick={toggleHome} src={darkMode ? Logo : LogoWhite}/>
            </FooterLogoWrapper>
            <SocialIcons>
              {/* <SocialIconLink href="/" target="_blank" aria-label="Facebook">
                <FaFacebook />
              </SocialIconLink> */}
              <SocialIconLink href="https://www.linkedin.com/company/rmd-trading-ag/" target="_blank" aria-label="LinkedIn">
                <FaLinkedin />
              </SocialIconLink>
              {/* <SocialIconLink href="/" target="_blank" aria-label="Instagram">
                <FaInstagram />
              </SocialIconLink> */}
            </SocialIcons>
          </FooterLinksWrapper>
        </FooterLinksContainer>
      </FooterWrapper>
    </FooterContainer>
  );
};

export default Footer;
