import Footer from "../Footer/Footer";
import {
  LanguageSwitchFlagg,
  LanguageSwitchWrapper,
  LayoutContainer,
  ThemeSwitch,
  ThemeSwitchText,
  ThemeSwitchWrapper,
} from "./LayoutElements";

import i18n from "i18next";
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';

const Layout = ({
  children,
  toggleDarkMode,
  darkMode,
  modalOpen,
  modalContent,
  toggleModal,
}) => {

  const { t } = useTranslation();

  const [lng, SetLng] = useState(i18n.languages[0]);
  const switchLanguage = () => {
    lng === "de" ? SetLng("en") : SetLng("de");
  }

  useEffect(() => {
    i18n.changeLanguage(lng)
  },[lng])

  return (
    <>
      <ThemeSwitchWrapper onClick={toggleDarkMode}>
        <ThemeSwitch $darkMode={darkMode} />
        <ThemeSwitchText>{darkMode ? `${t('switch.light_on')}` : `${t('switch.light_off')}`}</ThemeSwitchText>
      </ThemeSwitchWrapper>
      <LanguageSwitchWrapper>
        <LanguageSwitchFlagg
          src={`./images/${lng === "de" ? "en" : "de"}.svg`}
          onClick={switchLanguage}
        />
      </LanguageSwitchWrapper>
      <LayoutContainer>{children}</LayoutContainer>
      <Footer darkMode={darkMode} />
    </>
  );
};

export default Layout;
