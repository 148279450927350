import React from "react";
import { CloseIcon, Icon, SidebarContainer, SidebarLink, SidebarMenu, SidebarWrapper } from "./SidebarElements";
import { useTranslation } from 'react-i18next';
import { NavItems } from "../Navbar/NavItems";

const Sidebar = ({ isOpen, toggle }) => {

  const { t } = useTranslation();
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
      <Icon onClick={toggle}>
        <CloseIcon />
      </Icon>
      <SidebarWrapper>
        <SidebarMenu>
        <a href="https://shop.rmdtrading.ch" style={{display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '1.5rem', textDecoration: 'none', listStyle: 'none', transition: '0.2s ease-in-out', color: '#63d48c'}}>{t("menu.b2b_shop")}</a>
          { NavItems.map((element, i) => {
            return (<SidebarLink
              to={element.anchor}
              smooth={true}
              duration={500}
              spy={true}
              exact="true"
              offset={-80}
              onClick={toggle}
              color={element.color}
              key={`sidebarItem-${i}`}
            >
              {t(element.titel)}
            </SidebarLink>)
          })

          }
        </SidebarMenu>
      </SidebarWrapper>
    </SidebarContainer>
  );
};

export default Sidebar;
