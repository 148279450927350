import styled from "styled-components";
import { keyframes } from "styled-components";
import { Link } from "react-scroll";

export const HeroContainer = styled.div`
  background: ${(props) => props.theme.colors.background};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 ${(props) => props.theme.space[6]};
  height: 100vh;
  position: relative;
  z-index: 1;

  margin-bottom: 64px;

  @media screen and (max-width: ${props => props.theme.breakpoints.sm}) {
    height: 700px;
    margin-bottom: 64px;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.xs}) {
    font-size: 2rem;
  }

  // :before {
  //     content: "";
  //     position: absolute;
  //     top: 0;
  //     left: 0;
  //     right: 0;
  //     bottom: 0;
  //     background: linear-gradient(
  //         180deg,
  //         rgba(0, 0, 0, 0.2) 0%,
  //         rgba(0, 0, 0, 0.6) 100%
  //     ),
  //     linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, transparent 100%);
  //     z-index: 2;
  // }
`;

export const HeroBg = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const VideoBg = styled.video`
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  background: #232a34;
`;

export const HeroContent = styled.div`
  z-index: 3;
  max-width: 1200px;
  position: absolute;
  padding: 8px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const HeroLogo = styled.img`
  width: 100%;
  height: auto;
  max-width: 200px;
`;

export const HeroH1 = styled.h1`
  color: ${(props) => props.theme.colors.primary};
  font-size: 48px;
  text-align: center;
  position: relative;
  width: 100%;
  margin: ${(props) => props.theme.space[4]} 0;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
    font-size: 40px;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.xs}) {
    font-size: 32px;
  }
`;

export const HeroP = styled.p`
  margin-top: ${(props) => props.theme.space[4]};
  margin-bottom: 0;
  color: ${(props) => props.theme.colors.primary};
  font-size: 24px;
  text-align: center;
  max-width: 600px;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
    font-size: 24px;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.xs}) {
    font-size: 18px;
  }
`;

const pulse = keyframes`
0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(245, 133, 118, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(245, 133, 118, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(245, 133, 118, 0);
	}
`;

export const More = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 70px;
  position: absolute;
  bottom: 50px;
  border-radius: 50%;
  border: 2px solid ${(props) => props.theme.colors.primary};
  padding: 15px;
  color: ${(props) => props.theme.colors.primary};
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 1);
	transform: scale(1);
  animation: ${pulse} 2s infinite;
  cursor: pointer;
`;
