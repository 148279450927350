import styled from "styled-components";

export const CustomersContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 16px;
  padding-bottom: 150px;
  max-width: ${(props) => props.theme.maxWidth};
  margin: 0 auto;
`;

export const CustomersH1 = styled.h1`
  font-size: 2.5rem;
  color: ${(props) => props.theme.colors.primary};
  border-bottom: 2px solid ${(props) => props.theme.rmdRed};

  @media screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
    margin-bottom: 64px;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.xs}) {
    font-size: 2rem;
  }
`;

export const CustomersH2 = styled.h2`
  font-size: 1.8rem;
  color: ${(props) => props.theme.colors.primary};
  border-bottom: 2px solid ${(props) => props.theme.rmdGreen};
  margin-bottom: 64px;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.xs}) {
    font-size: 1.4rem;
  }
`;

export const CustomersCategoryWrapper = styled.div`
  margin-bottom: ${(props) => props.theme.space[4]};
`;

export const CustomersCategory = styled.div`
  display: grid;
  grid-auto-columns: minmax(auto, 1fr);
  align-items: center;
  grid-template-areas: ${({ imgStart }) =>
    !imgStart ? `'col2 col1'` : `'col1 col2'`};

  @media screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
    grid-template-areas: "col1" "col2";
    text-align: center;
    justify-items: center;
    margin-bottom: ${(props) => props.theme.space[5]};
  }
`;

export const CustomersCategoryContent = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col2;
`;

export const CustomersCategoryTitle = styled.h1`
  margin-bottom: 24px;
  font-size: 48px;
  line-height: 1.1;
  font-weight: 600;
  color: ${(props) => props.theme.colors.primary};

  @media screen and (max-width: ${(props) => props.theme.breakpoints.xs}) {
    font-size: 32px;
  }
`;

export const CustomersCategoryText = styled.p`
  color: ${(props) => props.theme.colors.primary};
  max-width: 440px;
  margin-bottom: 35px;
  font-size: 18px;
  line-height: 24px;
`;

export const CustomersCategoryImageWrapper = styled.div`
  margin-bottom: 15px;
  padding: 64px;
  grid-area: col1;
  width: 100%;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
    padding: 0 15px;
  }
`;

export const CustomersCategoryImage = styled.img`
  max-width: 555px;
  width: 100%;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
    max-width: 150px;
  }
`;

export const CustomersList = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.lg}) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.xs}) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const CustomerWrapperLink = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
    flex-direction: column;
  }
`;

export const CustomerWrapper = styled.div`
  display: flex;
  align-items: center;
  text-decoration: none;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
    flex-direction: column;
  }
`;

export const CustomerLogoWrapper = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 2px solid ${(props) => props.theme.colors.primary};
  background: ${({ Background }) => (Background ? Background : "#fff")};
  padding: 10px;
  z-index: 1;
`;

export const CustomerLogo = styled.img`
  height: 100%;
  width: 100%;
  object-fit: contain;
`;

export const CustomerInfo = styled.div`
  padding: 15px 15px 15px 25px;
  border: 2px solid ${(props) => props.theme.colors.primary};
  background: ${(props) => props.theme.colors.background};
  border-radius: 10px;
  display: flex;
  align-items: center;
  margin-left: -10px;
  flex: 1;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
    margin-left: 0;
    margin-top: -10px;
    width: 100%;
    justify-content: center;
  }
`;

export const CustomerName = styled.h2`
  color: ${(props) => props.theme.colors.primary};
  text-align: center;
  font-size: 20px;
`;
