import React from "react";
import {
  CustomerInfo,
  CustomerLogo,
  CustomerLogoWrapper,
  CustomerName,
  CustomersCategory,
  CustomersCategoryContent,
  CustomersCategoryImage,
  CustomersCategoryImageWrapper,
  CustomersCategoryText,
  CustomersCategoryTitle,
  CustomersCategoryWrapper,
  CustomersContainer,
  CustomersH1,
  CustomersH2,
  CustomersList,
  CustomerWrapper,
  CustomerWrapperLink,
} from "./CustomerElements";
import OnlineShop from "../../images/onlineshop.svg";
import Store from "../../images/shopping.svg";
import { customersList } from "./CustomersList";
import Plx from "react-plx";
import { useTranslation } from "react-i18next";
import fachhandel from "../../images/customers/fachhandel.svg";
import unknown from "../../images/customers/you.svg";

const Customers = () => {
  const { t } = useTranslation();

  const Title = [
    {
      start: "self",
      duration: 200,
      easing: [0.25, 0.1, 0.53, 1.3],
      properties: [
        {
          startValue: 100,
          endValue: 0,
          property: "translateY",
        },
        {
          startValue: 0,
          endValue: 1,
          property: "opacity",
        },
      ],
    },
  ];

  const LeftIn = [
    {
      start: "self",
      duration: 400,
      easing: "eseInOut",
      properties: [
        {
          startValue: -300,
          endValue: 0,
          property: "translateX",
        },
        {
          startValue: 0,
          endValue: 1,
          property: "opacity",
        },
      ],
    },
  ];

  const RightIn = [
    {
      start: "self",
      duration: 400,
      easing: "eseInOut",
      properties: [
        {
          startValue: 300,
          endValue: 0,
          property: "translateX",
        },
        {
          startValue: 0,
          endValue: 1,
          property: "opacity",
        },
      ],
    },
  ];

  const customerParallax = (i) => [
    {
      start: "self",
      duration: 200,
      easing: [0.25, 0.1, 0.53, 1.3],
      properties: [
        {
          startValue: 0,
          endValue: 1,
          property: "scale",
        },
      ],
    },
  ];

  const customerLogo = (i) => [
    {
      start: "self",
      duration: 200,
      easing: [0.25, 0.1, 0.53, 1.6],
      properties: [
        {
          startValue: 120,
          endValue: 0,
          property: "rotate",
        },
      ],
    },
  ];

  return (
    <>
      <CustomersContainer id="customers">
        <Plx parallaxData={Title}>
          <CustomersH1>{t("customers.title")}</CustomersH1>
        </Plx>
        <CustomersCategoryWrapper>
          <CustomersCategory imgStart={true}>
            <Plx parallaxData={RightIn}>
              <CustomersCategoryImageWrapper>
                <CustomersCategoryImage gridColumn={1} src={OnlineShop} />
              </CustomersCategoryImageWrapper>
            </Plx>
            <Plx parallaxData={LeftIn}>
              <CustomersCategoryContent>
                <CustomersCategoryTitle>
                  {t("customers.h1")}
                </CustomersCategoryTitle>
                <CustomersCategoryText>
                  {t("customers.t1")}
                </CustomersCategoryText>
              </CustomersCategoryContent>
            </Plx>
          </CustomersCategory>
          <CustomersCategory imgStart={false}>
            <Plx parallaxData={RightIn}>
              <CustomersCategoryImageWrapper>
                <CustomersCategoryImage src={Store} />
              </CustomersCategoryImageWrapper>
            </Plx>
            <Plx parallaxData={LeftIn}>
              <CustomersCategoryContent>
                <CustomersCategoryTitle>
                  {t("customers.h2")}
                </CustomersCategoryTitle>
                <CustomersCategoryText>
                  {t("customers.t2")}
                </CustomersCategoryText>
              </CustomersCategoryContent>
            </Plx>
          </CustomersCategory>
        </CustomersCategoryWrapper>
        <Plx parallaxData={Title}>
          <CustomersH2>{t("customers.our_customers")}</CustomersH2>
        </Plx>
        <CustomersList id="customerList">
          {customersList.map((customer, i) => {
            return (
              <Plx key={customer.name} parallaxData={customerParallax(i - 1)}>
                {customer.domain ? (
                  <CustomerWrapperLink href={customer.domain} target="_blank">
                    <CustomerLogoWrapper Background={customer.bg}>
                      <Plx
                        style={{ width: "100%", height: "100%" }}
                        parallaxData={customerLogo(i - 1)}
                      >
                        <CustomerLogo src={customer.logo} alt={customer.name} />
                      </Plx>
                    </CustomerLogoWrapper>
                    <CustomerInfo>
                      <CustomerName>{customer.name}</CustomerName>
                    </CustomerInfo>
                  </CustomerWrapperLink>
                ) : (
                  <CustomerWrapper href={customer.domain} target="_blank">
                    <CustomerLogoWrapper Background={customer.bg}>
                      <Plx
                        style={{ width: "100%", height: "100%" }}
                        parallaxData={customerLogo(i - 1)}
                      >
                        <CustomerLogo src={customer.logo} alt={customer.name} />
                      </Plx>
                    </CustomerLogoWrapper>
                    <CustomerInfo>
                      <CustomerName>{customer.name}</CustomerName>
                    </CustomerInfo>
                  </CustomerWrapper>
                )}
              </Plx>
            );
          })}
          <Plx key={t("customers.retailers")} parallaxData={customerParallax(customersList.lenght -1 )}>
              <CustomerWrapper href="#" target="_blank">
                <CustomerLogoWrapper Background="#fff">
                  <Plx
                    style={{ width: "100%", height: "100%" }}
                    parallaxData={customerLogo(customersList.lenght -1 )}
                  >
                    <CustomerLogo src={fachhandel} alt={t("customers.retailers")} />
                  </Plx>
                </CustomerLogoWrapper>
                <CustomerInfo>
                  <CustomerName>{t("customers.retailers")}</CustomerName>
                </CustomerInfo>
              </CustomerWrapper>
          </Plx>
          <Plx key={t("customers.you")} parallaxData={customerParallax(customersList.lenght -1 )}>
              <CustomerWrapper href="#" target="_blank">
                <CustomerLogoWrapper Background="#fff">
                  <Plx
                    style={{ width: "100%", height: "100%" }}
                    parallaxData={customerLogo(customersList.lenght )}
                  >
                    <CustomerLogo src={unknown} alt={t("customers.you")} />
                  </Plx>
                </CustomerLogoWrapper>
                <CustomerInfo>
                  <CustomerName>{t("customers.you")}</CustomerName>
                </CustomerInfo>
              </CustomerWrapper>
          </Plx>
        </CustomersList>
      </CustomersContainer>
    </>
  );
};

export default Customers;
