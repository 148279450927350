import React from "react";

const Pin = () => {
  return (
    <svg
      data-name="Ebene 4"
      xmlns="http://www.w3.org/2000/svg"
      width="1248.75"
      height="858.86"
      viewBox="0 0 1248.75 858.86"
    >
      <path
        d="M949.348,79.041a49.68,49.68,0,1,1,86.133,0l-43.067,77.243Z"
        transform="translate(-942.734 -4.578)"
      />
      <path
        fill="#f58576"
        d="M992.414,151.307,958.6,90.65a49.681,49.681,0,0,0,67.639,0Z"
        transform="translate(-942.734 -4.578)"
      />
      <path
        fill="#faf978"
        d="M1002.8,83.975c4.5-7.084,12.738-21.857,8.6-29.439a7.573,7.573,0,0,0-7.314-4.2,10.962,10.962,0,0,0-4.368,1.259,10.893,10.893,0,0,0-14.613,0,10.95,10.95,0,0,0-4.369-1.259,7.565,7.565,0,0,0-7.313,4.2c-4.135,7.582,4.1,22.355,8.6,29.439a29.739,29.739,0,1,1,20.771,0Z"
        transform="translate(-942.734 -4.578)"
      />
      <path
        fill="#faf978"
        d="M986.821,85.315c-4.456-6.44-14.057-22.758-10.534-29.218a4.331,4.331,0,0,1,4.276-2.51,7.422,7.422,0,0,1,2.664.709,11.39,11.39,0,0,0-.74,2.093c-1.011,4.043.112,8.37,2.5,9.645.745.4,2.712,1.065,4.871-1.324a6.5,6.5,0,0,0,1.054-7.088,11.5,11.5,0,0,0-3.048-4.138,7.863,7.863,0,0,1,9.094,0,11.508,11.508,0,0,0-3.048,4.138,6.5,6.5,0,0,0,1.055,7.088c2.158,2.388,4.125,1.722,4.871,1.324,2.39-1.275,3.514-5.6,2.5-9.645a11.464,11.464,0,0,0-.74-2.093,7.416,7.416,0,0,1,2.664-.709,4.337,4.337,0,0,1,4.275,2.51c3.524,6.46-6.077,22.778-10.533,29.218a29.508,29.508,0,0,1-11.187,0Z"
        transform="translate(-942.734 -4.578)"
      />
      <path
        fill="#faf978"
        d="M985.649,57.18a8.039,8.039,0,0,1,.324-1.015,8.064,8.064,0,0,1,1.929,2.7,3.243,3.243,0,0,1-.459,3.662c-.468.518-.825.683-.919.633C985.806,62.775,984.906,60.15,985.649,57.18Z"
        transform="translate(-942.734 -4.578)"
      />
      <path
        fill="#faf978"
        d="M998.856,56.165a8.378,8.378,0,0,1,.324,1.015c.742,2.97-.157,5.595-.875,5.978-.093.05-.451-.115-.919-.633a3.243,3.243,0,0,1-.459-3.662A8.064,8.064,0,0,1,998.856,56.165Z"
        transform="translate(-942.734 -4.578)"
      />
      <path
        fill="#f58576"
        d="M992.414,89.109a32.8,32.8,0,0,0,14.437-3.332V99.251a47.179,47.179,0,0,1-28.873,0V85.777A32.794,32.794,0,0,0,992.414,89.109Z"
        transform="translate(-942.734 -4.578)"
      />
      <path
        fill="#f58576"
        d="M992.414,7a47.249,47.249,0,0,1,17.7,91.063V83.941a33,33,0,1,0-35.391,0V98.064A47.249,47.249,0,0,1,992.414,7Z"
        transform="translate(-942.734 -4.578)"
      />
      <path
        opacity="0.5"
        d="M992.414,7a47.249,47.249,0,0,1,17.7,91.063V83.941a33,33,0,1,0-35.391,0V98.064A47.249,47.249,0,0,1,992.414,7Z"
        transform="translate(-942.734 -4.578)"
      />
    </svg>
  );
};

export default Pin;
