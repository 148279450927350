import styled from "styled-components";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

export const BrandsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 16px;
  padding-bottom: 150px;
  max-width: ${(props) => props.theme.maxWidth};
  margin: 0 auto;
`;

export const BrandsH1 = styled.h1`
  font-size: 2.5rem;
  color: ${(props) => props.theme.colors.primary};
  border-bottom: 2px solid ${(props) => props.theme.rmdGreen};
  margin-bottom: 64px;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
    margin-bottom: 64px;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.xs}) {
    font-size: 2rem;
  }
`;

export const BrandsH2 = styled.h2`
  font-size: 1.8rem;
  color: ${(props) => props.theme.colors.primary};
  border-bottom: 2px solid ${(props) => props.theme.rmdGreen};
  margin-bottom: 64px;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
    margin-bottom: 64px;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.xs}) {
    font-size: 1.6rem;
  }
`;

export const BrandsText = styled.div`
  max-width: 890px;
  color: ${(props) => props.theme.colors.primary};
  margin-bottom: 64px;
  text-align: center;
  font-size: 1.4rem;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
    margin-bottom: 64px;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.xs}) {
    font-size: 2rem;
    font-size: 1.2rem;
  }
`;

export const BrandResponsiveMasonary = styled(ResponsiveMasonry)`
  width: 100%;
`;

export const BrandMasonary = styled(Masonry)`
  width: 100%;
`;

export const MasonaryLogoWrapper = styled.div`
  border-radius: 10px;
  background: ${({$Background}) => $Background ? $Background : "#fff"};
  padding: 10px;
  display: flex;
  justify-content: center;

  & img {
    height: ${({$vertical}) => $vertical ? '100px' : '35px'};
    width: 100%;
  }
`

export const BrandsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
  width: 100%;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.xs}) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const BrandWrapper = styled.div`
  display: flex;
  flex-direction: ${({ $vertical }) => ($vertical ? "row" : "column")};
  align-items: stretch;
  height: 100%;
`;

export const BrandCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${(props) => props.theme.space[3]};
  border-radius: ${({ $vertical }) =>
    $vertical ? "0 10px 10px 0" : "0 0 10px 10px"};
  border: 2px solid ${(props) => props.theme.colors.primary};
  background: ${(props) => props.theme.colors.background};
  width: 100%;
  box-shadow: 0 1px 5px rgb(0 0 0 / 50%);
  align-items: center;
`;

export const BrandCardHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid ${(props) => props.theme.colors.primary + "50"};
  padding-bottom: ${(props) => props.theme.space[3]};
  width: 100%;
`;

export const BrandLogoWrapper = styled.div`
  width: 100%;
  height: 50px;
  border-radius: ${({ $vertical }) =>
    $vertical ? "10px 0 0 10px" : "10px 10px 0 0"};
  padding: 10px;
  background: ${({ $Background }) => ($Background ? $Background : "#fff")};

  & svg {
    height: 100%;
    width: 100%;
    object-fit: contain;
    fill: ${(props) => props.theme.colors.primary};
  }
`;

export const BrandLogo = styled.svg`
  height: 100%;
  width: 100%;
  object-fit: contain;
  fill: red;
`;

export const BrandName = styled.h2`
  font-size: 1.8rem;
  color: ${(props) => props.theme.colors.primary};
`;

export const BrandCategory = styled.p`
  color: ${(props) => props.theme.colors.primary};
  text-transform: uppercase;
  padding-top: ${(props) => props.theme.space[3]};
  text-align: center;
  padding-bottom: ${(props) => props.theme.space[4]};
`;

export const BrandToggleButton = styled.button`
  background: ${({ $Background }) => ($Background ? $Background : "#fff")};
  color: ${(props) => props.theme.colors.background};
  cursor: pointer;
  border: none;
  padding: ${(props) => props.theme.space[3]} ${(props) => props.theme.space[5]};
  font-size: 1rem;
  font-weight: bold;
  border-radius: 999px;
`;

export const BrandDescriptionWrapper = styled.div`
  max-height: ${({ $isOpen }) => ($isOpen ? "500px" : "20px")};
  transition: max-height 0.3s ease-in-out;
  width: 90%;
  padding: 10px;
  border: 2px solid ${(props) => props.theme.colors.primary};
  border-top: 0;
  border-radius: 0 0 10px 10px;
  color: ${(props) => props.theme.colors.primary};
  overflow: hidden;
`;
