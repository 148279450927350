import React from "react";
import {
  AboutContainer,
  AboutH1,
  AboutMap,
  AboutText,
  AboutTextWrapper,
  AboutWrapper,
  Mapmarker,
} from "./AboutElements";

import Pin from "../../images/Pin";
import Suisse from "../../images/Suisse";
import Plx from "react-plx";
import { useTranslation } from "react-i18next";

const About = () => {
  const markerAnimation = [
    {
      start: "self",
      startOffset: -50,
      duration: 600,
      easing: [0.25, 0.1, 0.53, 1.3],
      properties: [
        {
          startValue: -100,
          endValue: 0,
          property: "translateY",
        },
        {
          startValue: 0,
          endValue: 1,
          property: "opacity",
        },
      ],
    },
  ];

  const { t } = useTranslation();

  const blockAnimation = [
    {
      start: "#about",
      duration: 700,
      easing: "easeInOut",
      properties: [
        {
          startValue: -100,
          endValue: 0,
          property: "translateX",
        },
        {
          startValue: 0,
          endValue: 1,
          property: "opacity",
        },
      ],
    },
  ];

  return (
    <AboutContainer id="about">
      <AboutH1>{t("about_us.title")}</AboutH1>
      <AboutWrapper>
        <AboutTextWrapper>
          <Plx parallaxData={blockAnimation}>
            <AboutText>
              <p>{t("about_us.p1")}</p>
              <p>{t("about_us.p2")}</p>
              <p>{t("about_us.p3")}</p>
            </AboutText>
          </Plx>
        </AboutTextWrapper>
        <AboutMap>
          <Suisse />
          <Mapmarker>
            <Plx parallaxData={markerAnimation}>
              <Pin />
            </Plx>
          </Mapmarker>
        </AboutMap>
      </AboutWrapper>
    </AboutContainer>
  );
};

export default About;
