import React from "react";
import Plx from "react-plx";
import Brands from "../components/Brands/Brands";
import Customers from "../components/Customers/Customers";
import Hero from "../components/Hero/Hero";
import Wedo from "../components/Wedo/Wedo";
import Layout from "../components/Layout/Layout";
import About from "../components/About/About";
import ContactForm from "../components/ContactForm/ContactForm";

const Home = ({ toggleDarkMode, darkMode }) => {

  const parallaxHero = [
    {
      start: 0,
      duration: "#home",  
      properties: [
        {
          startValue: 1,
          endValue: 0,
          property: "opacity"
        }
      ]
    }
  ]
  
  return (
    <>
      <Plx parallaxData={parallaxHero}>
        <Hero />
      </Plx>
      <Layout toggleDarkMode={toggleDarkMode} darkMode={darkMode}>
      <About />
      <Wedo />
      <Brands />
      <Customers />
      <ContactForm />
      </Layout>
    </>
  );
};

export default Home;
