import {
  BrandMasonary,
  BrandResponsiveMasonary,
  BrandsContainer,
  BrandsH1,
  BrandsH2,
  BrandsText,
  MasonaryLogoWrapper,
} from "./BrandsElements";
import { BrandsList } from "./BrandsList";
import Plx from "react-plx";
import { useTheme } from "styled-components";
import { useTranslation } from "react-i18next";

const Brands = () => {
  const theme = useTheme();

  const {t} = useTranslation();
  const BrandsTitle = [
    {
      start: "self",
      duration: 200,
      easing: [0.25, 0.1, 0.53, 1.3],
      properties: [
        {
          startValue: 100,
          endValue: 0,
          property: "translateY",
        },
        {
          startValue: 0,
          endValue: 1,
          property: "opacity",
        },
      ],
    },
  ];

  const Brands = [
    {
      start: "self",
      duration: 200,
      easing: [0.25, 0.1, 0.53, 1.1],
      properties: [
        {
          startValue: 100,
          endValue: 0,
          property: "translateY",
        },
        {
          startValue: 0,
          endValue: 1,
          property: "opacity",
        },
      ],
    },
  ];

  const brandColors = [theme.rmdRed, theme.rmdBlue, theme.rmdGreen];
  
  return (
    <>
      <BrandsContainer id="angebot">
        <Plx parallaxData={BrandsTitle}>
          <BrandsH1>{t('offer.title')}</BrandsH1>
        </Plx>
        <Plx parallaxData={BrandsTitle}>
          <BrandsText>
            <p>
            {t('offer.text')}
            </p>
          </BrandsText>
        </Plx>
        <Plx parallaxData={BrandsTitle}>
          <BrandsH2>{t('offer.our_brands')}</BrandsH2>
        </Plx>
        <BrandResponsiveMasonary
          columnsCountBreakPoints={{
            [theme.breakpointsInt.xs]: 3,
            [theme.breakpointsInt.sm]: 4,
            [theme.breakpointsInt.lg]: 6,
          }}
        >
          <BrandMasonary gutter="10px">
            {BrandsList.map((brand) => {
              return (
                <Plx key={brand.name} parallaxData={Brands}>
                  <MasonaryLogoWrapper
                    $Background={
                      brandColors[
                        Math.floor(Math.random() * brandColors.length)
                      ]
                    }
                    $vertical={brand.vertical}
                  >
                    <img src={brand.logo}  alt={brand.name}/>
                  </MasonaryLogoWrapper>
                </Plx>
              );
            })}
          </BrandMasonary>
        </BrandResponsiveMasonary>
      </BrandsContainer>
    </>
  );
};

export default Brands;
