import React, { useEffect, useState } from "react";
import { FaBars } from "react-icons/fa";
import Logo from "../../images/RMD Logo_Lang.svg";
import LogoWhite from "../../images/RMD Logo_Weiss.svg";
import {
  MobileNavIcon,
  Nav,
  NavbarContainer,
  NavItem,
  NavLinks,
  NavLogo,
  NavLogoLink,
  NavMenu
} from "./NavbarElements";
import { animateScroll as scroll } from "react-scroll";
import { useTranslation } from 'react-i18next';

import { NavItems } from "./NavItems";

const Navbar = ({ toggle, darkMode }) => {
  const [scrollNav, setScrollNav] = useState(false);

  const { t } = useTranslation();

  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeNav);
  }, []);

  const toggleHome = () => {
    scroll.scrollToTop();
  };

  return (
    <>
      <Nav scrollNav={scrollNav}>
        <NavbarContainer>
          <NavLogoLink to="/" onClick={toggleHome}>
            <NavLogo src={darkMode ? Logo : LogoWhite} />
          </NavLogoLink>
          <MobileNavIcon onClick={toggle}>
            <FaBars />
          </MobileNavIcon>
          <NavMenu>
            <a style={{textDecoration: 'none', color: '#fff', background: '#63d48c', padding: '5px 15px', borderRadius: '5px', fontWeight: 600}} href="https://shop.rmdtrading.ch">{t("menu.b2b_shop")}</a>
            {NavItems.map((element,i) => {
              return (
                <>
                <NavItem key={`navItem-${i}`}>
                  <NavLinks
                    to={element.anchor}
                    smooth={true}
                    duration={500}
                    spy={true}
                    exact="true"
                    offset={-80}
                    color={element.color}
                  >
                    {t(element.titel)}
                  </NavLinks>
                </NavItem>
                </>
              );
            })}
          </NavMenu>
        </NavbarContainer>
      </Nav>
    </>
  );
};

export default Navbar;
