import React, { useState } from "react";
import {
  ContactContainer,
  ContactFormGroup,
  ContactFormInput,
  ContactFormInputGroup,
  ContactFormLabel,
  ContactFormSubmit,
  ContactFormTextArea,
  ContactFormThankYouWrapper,
  ContactFormular,
  ContactH1,
  ContactInfo,
  ContactInfoImage,
  ContactInfoItem,
  ContactInfoWrapper,
  ThankYouHeadline,
  ThankYouImage,
  ThankYouImgWrapper,
  ThankYouSubHeading,
  ThankYouText,
} from "./ContactFormElements";

import thankYouImg from "../../images/rmd-message.svg";
import mail from "../../images/mail";
import phone from "../../images/phone";
import location from "../../images/location";
import { useTheme } from "styled-components";
import { Bars } from "react-loader-spinner";
import Plx from "react-plx";
import { useTranslation } from "react-i18next";

const FORM_ENDPOINT =
  "https://public.herotofu.com/v1/1fe2eff0-212b-11ed-a969-f1ede4dc5ccf";

const ContactForm = () => {
  const [status, setStatus] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [formErrors, setFormErrors] = useState({
    name: "",
    company: "",
    email: "",
    phone: "",
  });

  const {t} = useTranslation();

  const theme = useTheme();

  const iconPop = [
    {
      start: "self",
      duration: 200,
      easing: [0.25, 0.1, 0.53, 1.3],
      properties: [
        {
          startValue: 0,
          endValue: 1,
          property: "scale",
        },
      ],
    },
  ];

  const validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  const validatePhone = (phone) => {
    return !phone.match(/[a-zA-Z]/);
  };

  const checkFormData = (e) => {
    e.preventDefault();

    setIsLoading(true);

    const errors = { ...formErrors };

    e.target.elements.name.value === "" &&
      (errors.name = `${t("contact.messages.missing_name")}`);
    e.target.elements.company.value === "" &&
      (errors.company = `${t("contact.messages.company")}`);
    !validateEmail(e.target.email.value) && (errors.email = `${t("contact.messages.invalid_email")}`);
    (!validatePhone(e.target.phone.value) ||
      e.target.phone.value === "" ||
      e.target.phone.value.replace(/\s/g, "").length < 10) &&
      (errors.phone = `${t("contact.messages.invalid_phone")}`);
    setFormErrors(errors);

    Object.values(errors).every((value) => value === "") ? handleSubmit(e) : setIsLoading(false);
  };

  const checkInput = (e) => {
    switch (e.target.name) {
      case "name":
        e.target.value !== ""
          ? setFormErrors({ ...formErrors, name: "" })
          : setFormErrors({ ...formErrors, name: `${t("contact.messages.missing_name")}` });
        break;
      case "company":
        e.target.value !== ""
          ? setFormErrors({ ...formErrors, company: "" })
          : setFormErrors({ ...formErrors, company: `${t("contact.messages.company")}` });
        break;
      case "email":
        validateEmail(e.target.value)
          ? setFormErrors({ ...formErrors, email: "" })
          : setFormErrors({ ...formErrors, email: `${t("contact.messages.invalid_email")}` });
        break;
      case "phone":
        validatePhone(e.target.value) &&
        e.target.value !== "" &&
        e.target.value.replace(/\s/g, "").length >= 10
          ? setFormErrors({ ...formErrors, phone: "" })
          : setFormErrors({ ...formErrors, phone: `${t("contact.messages.invalid_phone")}` });
        break;
      default:
        break;
    }
  };

  const handleSubmit = (e) => {
    console.log(e.target.elements);

    const inputs = e.target.elements;
    const data = {};

    for (let i = 0; i < inputs.length; i++) {
      if (inputs[i].name) {
        data[inputs[i].name] = inputs[i].value;
      }
    }

    fetch(FORM_ENDPOINT, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.status === 422) {
          e.target.submit();
          throw new Error("Please finish the captcha challenge");
        }

        if (response.status !== 200) {
          throw new Error(response.statusText);
        }

        return response.json();
      })
      .then(() => setStatus("We'll be in touch soon."))
      .then(() =>  window.scrollTo({top: document.getElementById('contact').getBoundingClientRect().top + window.pageYOffset - 80, behavior: "smooth"}))
      .then(() => setIsLoading(false))
      .catch((err) => setStatus(err.toString()));
  };

  return (
    <ContactContainer id="contact">
      <ContactH1>{t("contact.title")}</ContactH1>
      {status !== "" ? (
        <ContactFormThankYouWrapper>
          <ThankYouText>
            <ThankYouHeadline>{t("contact.messages.thanks")}</ThankYouHeadline>
            <ThankYouSubHeading>
            {t("contact.messages.received")}
            </ThankYouSubHeading>
          </ThankYouText>
          <ThankYouImgWrapper>
            <ThankYouImage src={thankYouImg} />
          </ThankYouImgWrapper>
        </ContactFormThankYouWrapper>
      ) : (
        <>
          <ContactInfoWrapper>
            <ContactInfoItem>
              <Plx parallaxData={iconPop}>
                <ContactInfoImage $color={theme.rmdGreen}>
                  {phone}
                </ContactInfoImage>
              </Plx>
              <ContactInfo>+41 76 698 98 78</ContactInfo>
            </ContactInfoItem>
            <ContactInfoItem>
              <Plx parallaxData={iconPop}>
                <ContactInfoImage $color={theme.rmdBlue}>
                  {mail}
                </ContactInfoImage>
              </Plx>
              <ContactInfo>info@rmdtrading.ch</ContactInfo>
            </ContactInfoItem>
            <ContactInfoItem>
              <Plx parallaxData={iconPop}>
                <ContactInfoImage $color={theme.rmdRed}>
                  {location}
                </ContactInfoImage>
              </Plx>
              <ContactInfo>
                Niederfeld 48
                <br />
                9320 Stachen
              </ContactInfo>
            </ContactInfoItem>
          </ContactInfoWrapper>
          <ContactFormular
            action={FORM_ENDPOINT}
            onSubmit={checkFormData}
            method="POST"
            target="_blank"
          >
            <ContactFormGroup>
              <ContactFormInputGroup>
                <ContactFormLabel
                  $error={formErrors.name !== "" ? true : false}
                  htmlFor="name"
                >
                  {t("contact.name")}
                </ContactFormLabel>
                <ContactFormInput
                  $error={formErrors.name !== "" ? true : false}
                  onChange={checkInput}
                  type="text"
                  name="name"
                />
                {formErrors.name !== "" && <small>{formErrors.name}</small>}
              </ContactFormInputGroup>
              <ContactFormInputGroup>
                <ContactFormLabel
                  $error={formErrors.company !== "" ? true : false}
                  htmlFor="company"
                >
                  {t("contact.company")}
                </ContactFormLabel>
                <ContactFormInput
                  $error={formErrors.company !== "" ? true : false}
                  onChange={checkInput}
                  type="text"
                  name="company"
                />
                {formErrors.company !== "" && (
                  <small>{formErrors.company}</small>
                )}
              </ContactFormInputGroup>
            </ContactFormGroup>
            <ContactFormGroup>
              <ContactFormInputGroup>
                <ContactFormLabel
                  $error={formErrors.email !== "" ? true : false}
                  htmlFor="email"
                >
                  {t("contact.mail")}
                </ContactFormLabel>
                <ContactFormInput
                  $error={formErrors.email !== "" ? true : false}
                  onChange={checkInput}
                  type="email"
                  name="email"
                />
                {formErrors.email !== "" && <small>{formErrors.email}</small>}
              </ContactFormInputGroup>
              <ContactFormInputGroup>
                <ContactFormLabel
                  $error={formErrors.phone !== "" ? true : false}
                  htmlFor="phone"
                >
                  {t("contact.phone")}
                </ContactFormLabel>
                <ContactFormInput
                  $error={formErrors.phone !== "" ? true : false}
                  onChange={checkInput}
                  type="tel"
                  name="phone"
                />
                {formErrors.phone !== "" && <small>{formErrors.phone}</small>}
              </ContactFormInputGroup>
            </ContactFormGroup>
            <ContactFormGroup>
              <ContactFormInputGroup>
                <ContactFormLabel htmlFor="message">
                {t("contact.message")}
                </ContactFormLabel>
                <ContactFormTextArea name="message" />
              </ContactFormInputGroup>
            </ContactFormGroup>
            <ContactFormGroup>
              <ContactFormSubmit type="submit">
                { isLoading ? <Bars
                  height="40"
                  width="80"
                  color="#fff"
                  ariaLabel="bars-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                /> : `${t("contact.send")}`}
              </ContactFormSubmit>
            </ContactFormGroup>
          </ContactFormular>
        </>
      )}
    </ContactContainer>
  );
};

export default ContactForm;
