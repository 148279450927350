export const base = {
    breakpoints: {'xs': '576px', 'sm': '768px', 'md': '992px', 'lg': '1200px', 'xl': '1400px'},
    breakpointsInt: {'xs': '576', 'sm': '768', 'md': '992', 'lg': '1200', 'xl': '1400'},
    maxWidth: "1400px",
    space: ['0px', '2px', '4px', '8px', '16px', '32px', '64px'],
    font: {
        heading: 'Encode Sans Expanded, sans-serif',
        body: 'Encode Sans Expanded, sans-serif',
    },
    fontSizes: ['12px', '14px', '16px', '20px', '24px'],
    rmdBlue: '#7ab0eb',
    rmdRed: '#f58576',
    rmdGreen: '#63d48c',
}

export const light = {
    primary: '#1f2023',
    background: '#ffffff',
    footerBg: '#f3f3f3',
    nav: '#f8f8f8',
    border: '#deebf1',
    text: '#202224'
}

export const dark = {
    primary: '#ffffff',
    background: '#1f2023',
    footerBg: '#0e0f10',
    nav: '#27282b',
    border: '#303236',
    text: '#f8f8f8',
}