import styled from "styled-components";

export const AboutContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 16px;
  padding-bottom: 150px;
  max-width: ${(props) => props.theme.maxWidth};
  margin: 0 auto;
`;

export const AboutH1 = styled.h1`
  font-size: 2.5rem;
  color: ${(props) => props.theme.colors.primary};
  border-bottom: 2px solid ${(props) => props.theme.rmdRed};
  margin-bottom: 64px;
  z-index: 1;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
    margin-bottom: 64px;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.xs}) {
    font-size: 2rem;
  }
`;

export const AboutWrapper = styled.div`
  display: flex;
  position: relative;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
    flex-direction: column-reverse;
    align-items: center;
  }
`;

export const AboutTextWrapper = styled.div`
  position: absolute;
  width: 50%;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);

  @media screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
    position: relative;
    transform: translateY(-20%);
    width: 90%;
  }
`;



export const AboutText = styled.div`
  background: ${(props) => props.theme.rmdRed};
  border-radius: 16px;
  font-size: 1.2rem;
  padding: 16px;
  color: ${(props) => props.theme.colors.primary};

  & p:not(:last-child) {
    margin-bottom: 8px;
  }
`;

export const AboutMap = styled.div`
  flex: 1;
  position: relative;

  & svg {
    width: 100%;
    height: auto;
  }
`;

export const Mapmarker = styled.div`
  position: absolute;
  top: 0;

  & svg {
    transform: translateX(72.306%);
  }
`;
