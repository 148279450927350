import React from "react";
import {
  WedoBgShape,
  WedoBottom,
  WedoContainer,
  WedoH1,
  WedoImg,
  WedoItem,
  WedoItemP,
  WedoItemTitle,
  WedoTop,
  WedoWrapper,
} from "./WedoElements";
import { useTheme } from "styled-components";
import { useTranslation } from "react-i18next";

import Plx from "react-plx";

import bgShape1 from "../../images/bg-shape-1";
import importSVG from "../../images/importImg.svg";
import network from "../../images/network.svg";
import marketing from "../../images/marketing.svg";

const Wedo = () => {
  const theme = useTheme();
  const {t} = useTranslation()

  const WedoTitle = [
    {
      start: "self",
      startOffset: -50,
      duration: 200,
      easing: [0.25, 0.1, 0.53, 1.3],
      properties: [
        {
          startValue: 100,
          endValue: 0,
          property: "translateY",
        },
        {
          startValue: 0,
          endValue: 1,
          property: "opacity",
        },
      ],
    },
  ];

  return (
    <>
      <WedoContainer id="work">
        <Plx parallaxData={WedoTitle}>
          <WedoH1>{t("services.title")}</WedoH1>
        </Plx>
        <WedoWrapper>
          <Plx style={{flex: 1}} parallaxData={WedoTitle}>
            <WedoItem>
                <WedoBgShape color={theme.rmdBlue}>{bgShape1}</WedoBgShape>
              <WedoTop>
                <WedoItemTitle color={theme.rmdBlue}>{t('services.h1')}</WedoItemTitle>
                <WedoItemP>
                  {t("services.t1")}
                </WedoItemP>
              </WedoTop>
              <WedoBottom>
                <WedoImg src={importSVG} />
              </WedoBottom>
            </WedoItem>
          </Plx>
          <Plx style={{flex: 1}} parallaxData={WedoTitle}>
            <WedoItem>
              <WedoBgShape color={theme.rmdRed}>{bgShape1}</WedoBgShape>
              <WedoTop>
                <WedoItemTitle color={theme.rmdRed}>{t('services.h2')}</WedoItemTitle>
                <WedoItemP>
                {t('services.t2')}
                </WedoItemP>
              </WedoTop>
              <WedoBottom>
                <WedoImg src={network} />
              </WedoBottom>
            </WedoItem>
          </Plx>
          <Plx style={{flex: 1}} parallaxData={WedoTitle}>
            <WedoItem>
              <WedoBgShape color={theme.rmdGreen}>{bgShape1}</WedoBgShape>
              <WedoTop>
                <WedoItemTitle color={theme.rmdGreen}>
                {t("services.h3")}
                </WedoItemTitle>
                <WedoItemP>
                {t("services.t3")}
                </WedoItemP>
                <WedoItemP>
                  {t("services.t4")}
                </WedoItemP>
              </WedoTop>
              <WedoBottom>
                <WedoImg src={marketing} />
              </WedoBottom>
            </WedoItem>
          </Plx>
        </WedoWrapper>
      </WedoContainer>
    </>
  );
};

export default Wedo;
