import "./App.css";
import { ThemeProvider } from "styled-components";
import { base, dark, light } from "./theme/theme";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import { Suspense, useEffect, useMemo, useState } from "react";
import Sidebar from "./components/Sidebar/Sidebar";
import Navbar from "./components/Navbar/Navbar";

function App() {
  const [darkMode, setDarkMode] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const theme = useMemo(
    () => ({ ...base, colors: darkMode ? dark : light }),
    [darkMode]
  );

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    document.body.style.backgroundColor = theme.colors.background;
  }, [theme]);

  return (
    <Suspense fallback="...is loading">
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Sidebar toggle={toggle} isOpen={isOpen} />
          <Navbar darkMode={darkMode} toggle={toggle} />
          <Routes>
            <Route
              path="/"
              element={
                <Home toggleDarkMode={toggleDarkMode} darkMode={darkMode} />
              }
              exact
            />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </Suspense>
  );
}

export default App;
