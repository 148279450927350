import React from "react";
import {
  HeroContainer,
  HeroContent,
  HeroH1,
  HeroLogo,
  HeroP,
  More,
} from "./HeroElements";
import Logo from "../../images/RMD Logo.svg";
import { FaChevronDown } from "react-icons/fa";
import { useTranslation } from 'react-i18next';

const Hero = () => {
  const {t} = useTranslation();
  return (
    <HeroContainer id="home">
      <HeroContent>
        <HeroLogo src={Logo} />
        <HeroH1>{t('company.name')}</HeroH1>
        <HeroP>{t('hero.text')}</HeroP>
      </HeroContent>
      <More to="about"
              smooth={true}
              duration={500}
              spy={true}
              exact="true"
              offset={-80}><FaChevronDown /></More>
    </HeroContainer>
  );
};

export default Hero;
