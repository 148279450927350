import styled from "styled-components";

export const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 16px;
  padding-bottom: 150px;
  max-width: ${(props) => props.theme.maxWidth};
  margin: 0 auto;
`;

export const ContactH1 = styled.h1`
  font-size: 2.5rem;
  color: ${(props) => props.theme.colors.primary};
  border-bottom: 2px solid ${(props) => props.theme.rmdBlue};
  margin-bottom: 64px;
  z-index: 1;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
    margin-bottom: 64px;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.xs}) {
    font-size: 2rem;
  }
`;

export const ContactFormular = styled.form`
  width: 100%;
`;

export const ContactFormGroup = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 16px;
  justify-content: center;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
    flex-direction: column;
  }
`;

export const ContactFormInputGroup = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  & small {
    color: ${(props) => props.theme.rmdRed};
  }
`;

export const ContactFormLabel = styled.label`
  margin-bottom: 8px;
  color: ${(props) =>
    props.$error ? props.theme.rmdRed : props.theme.colors.primary};
  font-size: 0.8rem; ;
`;

export const ContactFormInput = styled.input`
  background: ${(props) => props.theme.colors.background};
  color: ${(props) => props.theme.colors.primary};
  padding: 8px 16px;
  border: 1px solid
    ${(props) =>
      props.$error ? props.theme.rmdRed : props.theme.colors.primary};
  outline: none;
  border-radius: 8px;
  font-size: 1.2rem;
`;

export const ContactFormTextArea = styled.textarea`
  background: ${(props) => props.theme.colors.background};
  color: ${(props) => props.theme.colors.primary};
  margin-bottom: 16px;
  width: 100%;
  padding: 8px 16px;
  border: 1px solid ${(props) => props.theme.colors.primary};
  outline: none;
  border-radius: 8px;
  font-size: 1.2rem;
  font-family: inherit;
`;

export const ContactFormSubmit = styled.button`
  background: ${(props) => props.theme.rmdBlue};
  color: #fff;
  font-size: 1.6rem;
  border-radius: 8px;
  padding: 8px 16px;
  border: none;
  outline: none;
  cursor: pointer;
  text-transform: uppercase;
  width: 100%;
  max-width: 500px;
  text-align: center;
  display: flex;
  justify-content: center;
  height: 50px;
  align-items: center;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.xs}) {
    font-size: 1.2rem;
  }
`;

export const ContactFormThankYouWrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 700px;
  gap: 32px;
  padding: 0 32px;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.xs}) {
    flex-direction: column;
  }
`;

export const ThankYouImgWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const ThankYouImage = styled.img`
  max-height: 150px;
`;

export const ThankYouText = styled.div``;

export const ThankYouHeadline = styled.h2`
  color: ${(props) => props.theme.colors.primary};
  margin: 0;
  font-size: 1.8rem;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.xs}) {
    text-align: center;
  }
`;

export const ThankYouSubHeading = styled.p`
  color: ${(props) => props.theme.colors.primary};
  font-size: 1.4rem;
  margin-top: 16px;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.xs}) {
    text-align: center;
  }
`;

export const ContactInfoWrapper = styled.div`
  display: flex;
  width: 100%;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.xs}) {
    flex-direction: column;
    padding-bottom: 64px;
  }
`;

export const ContactInfoItem = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  flex: 1;
`;

export const ContactInfoImage = styled.div`
  display: flex;
  justify-content: center;

  & svg {
    fill: ${(props) => (props.$color ? props.$color : props.theme.rmdRed)};
    max-height: 50px;
    height: auto;
  }
`;

export const ContactInfo = styled.p`
  color: ${(props) => props.theme.colors.primary};
  font-size: 1.4rem;
  text-align: center;
  flex: 1;
  align-items: center;
  display: flex;
  justify-content: center;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
    font-size: 1.2rem;
  }
`;
