import styled from "styled-components";

export const WedoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 16px;
  padding-bottom: 150px;
  max-width: ${(props) => props.theme.maxWidth};
  margin: 0 auto;
`;

export const WedoH1 = styled.h1`
  font-size: 2.5rem;
  color: ${(props) => props.theme.colors.primary};
  border-bottom: 2px solid ${(props) => props.theme.rmdBlue};
  margin-bottom: 64px;
  z-index: 1;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
    margin-bottom: 64px;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.xs}) {
    font-size: 2rem;
  }
`;

export const WedoWrapper = styled.div`
  display: flex;
  gap: 24px;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
    flex-direction: column;
    gap: 48px;
  }
`;

export const WedoItem = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1;
`;

export const WedoBgShape = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  transform: translate(-20%, -10%);
  height: 100%;
  & svg {
    fill: ${(props) => (props.color ? props.color : props.theme.rmdBlue)};
    height: 50%;
    width: auto;
  }
`;

export const WedoItemTitle = styled.h2`
  font-size: 1.8rem;
  margin-bottom: ${(props) => props.theme.space[4]};
  color: ${(props) => props.theme.colors.primary};
  border-bottom: 2px solid
    ${(props) => (props.color ? props.color : props.theme.rmdBlue)};
`;

export const WedoItemP = styled.p`
  color: ${(props) => props.theme.colors.primary};
  margin-bottom: 8px;
  font-size: 1.2rem;
`;

export const WedoTop = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  z-index: 1;
`;

export const WedoBottom = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding-top: 16px;
  flex: 1;
`;

export const WedoImg = styled.img`
  max-height: 120px;
  width: auto;
`;
