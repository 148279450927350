import galaxus from '../../images/customers/galaxus.svg';
import microspot from '../../images/customers/microspot.svg';
import franzCarlWeber from '../../images/customers/franz-carl-weber.svg';
import brack from "../../images/customers/brack.svg";
import orellFüssli from "../../images/customers/orellfüssli.svg";
import jugglux from "../../images/customers/jugglux.svg";
import interdiscount from "../../images/customers/interdiscount.svg";

import derdealer from "../../images/customers/derdealer.svg";
import digitec from "../../images/customers/digitec.svg";
import nettoshop from "../../images/customers/nettoshop.svg";

import decathlon from "../../images/customers/decathlon.svg";
import manor from "../../images/customers/manor.svg";
import luethy_stocker from "../../images/customers/luethy_stocker.svg";
import tchibo from "../../images/customers/tchibo.svg";
import spielkiste from "../../images/customers/spielkiste.svg";

import spielhimmel from "../../images/customers/spielhimmel.svg";
import paddysport from "../../images/customers/paddysport.svg";



export const customersList = [
    {
        name: "Galaxus",
        domain: "https://galaxus.ch",
        type: "online",
        logo: galaxus,
        bg: "#fff"
    },
    {
        name: "Franz Carl Weber",
        domain: "https://www.fcw.ch/de/",
        type: "stationär",
        logo: franzCarlWeber,
        bg: "#e30613"
    },
    {
        name: "Digitec",
        domain: "https://digitec.ch/",
        type: "online",
        logo: digitec,
        bg: "#fff"
    },
    {
        name: "Lüthy + Stocker AG",
        domain: "https://buchhaus.ch/",
        type: "Fachhandel",
        logo: luethy_stocker,
        bg: "#fff"
    },
    {
        name: "nettoshop.ch",
        domain: "https://nettoshop.ch/",
        type: "online",
        logo: nettoshop,
        bg: "#fff"
    },
    {
        name: "Microspot",
        domain: "https://microspot.ch",
        type: "online",
        logo: microspot,
        bg: "#00ae6d"
    },
    {
        name: "BRACK.CH",
        domain: "https://www.brack.ch/",
        type: "online",
        logo: brack,
        bg: "#fff"
    },
    {
        name: "derdealer.ch",
        domain: "https://derdealer.ch/",
        type: "online",
        logo: derdealer,
        bg: "#fff"
    },
    {
        name: "Orell Füssli",
        domain: "https://www.orellfuessli.ch/",
        type: "online",
        logo: orellFüssli,
        bg: "#fff"
    },
    {
        name: "Decathlon",
        domain: "https://www.decathlon.ch/de/",
        type: "online",
        logo: decathlon,
        bg: "#fff"
    },
    {
        name: "Manor",
        domain: "https://www.manor.ch/de",
        type: "online",
        logo: manor,
        bg: "#fff"
    },
    {
        name: "Tchibo",
        domain: "https://www.tchibo.ch/",
        type: "online",
        logo: tchibo,
        bg: "#fff"
    },
    {
        name: "Jugglux",
        domain: "https://www.jugglux.ch/",
        type: "online",
        logo: jugglux,
        bg: "#fff"
    },
    {
        name: "Spielkiste",
        domain: "https://spielkiste.ch/",
        type: "online",
        logo: spielkiste,
        bg: "#fff"
    },
    {
        name: "Interdiscount",
        domain: "https://www.interdiscount.ch/de",
        type: "online",
        logo: interdiscount,
        bg: "#fff"
    },
    {
        name: "Päddy Sport",
        domain: "https://www.paddysport.ch/",
        type: "stationär",
        logo: paddysport,
        bg: "#fff"
    },
    {
        name: "Spielhimmel",
        domain: "https://www.spielhimmel.ch/",
        type: "online",
        logo: spielhimmel,
        bg: "#fff"
    }
]