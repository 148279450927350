import styled from "styled-components";
import { FaLightbulb } from "react-icons/fa";

export const LayoutContainer = styled.div`
  background: ${(props) => props.theme.colors.background};
  overflow: hidden;
  padding: 0 ${(props) => props.theme.space[4]};
`;

export const ThemeSwitchWrapper = styled.div`
  border-radius: 10px 10px 0 0;
  background: ${(props) => props.theme.colors.background};
  border: 1px solid ${(props) => props.theme.colors.primary};
  position: fixed;
  right: 0;
  top: 50%;
  transform: rotate(270deg);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 13px 5px 7px;
  transform-origin: right bottom;
  cursor: pointer;
  text-transform: uppercase;
`;

export const ThemeSwitch = styled(FaLightbulb)`
  color: ${({ $darkMode }) => ($darkMode ? "#FAF978" : "#000")};
  font-size: 1.8rem;
  margin-right: 8px;
`;

export const ThemeSwitchText = styled.span`
  color: ${(props) => props.theme.colors.primary};
`;

export const LanguageSwitchWrapper = styled.div`
  border-radius: 10px 0 0 10px;
  background: ${(props) => props.theme.colors.background};
  border: 1px solid ${(props) => props.theme.colors.primary};
  position: fixed;
  right: 0;
  top: 50%;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px 5px 7px 5px;
  cursor: pointer;
`;

export const LanguageSwitchFlagg = styled.img`
  width: 1.8rem;
  border-radius: 5px;
`;
